import { gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { MutationDeleteProductArgs } from "@/graphql/types";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";

type DeleteProductData = {
  deleteProduct: number;
};

const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: Int!) {
    deleteProduct(id: $id)
  }
`;

export const useDeleteProduct = () => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();
  const {
    loading: removeLoading,
    onDone,
    mutate,
  } = useMutation<DeleteProductData, MutationDeleteProductArgs>(DELETE_PRODUCT);
  onDone(({ data, errors }) => {
    if (data?.deleteProduct) {
      toast.add({
        severity: "success",
        summary: t("product.delete.title"),
        detail: t(`product.delete.success`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      const unable =
        errors[0].message === "used" || errors[0].extensions?.exception?.code;
      let life = parseInt(process.env.VUE_APP_TOAST_LIFE);
      life += unable ? 2000 : 0;
      toast.add({
        severity: "warn",
        summary: t("product.delete.title"),
        detail: t(`product.delete.${unable ? "unable" : "failed"}`),
        life,
      });
    }
  });
  function submitDelete(id: number) {
    const data = {
      message: t("delete.confirm"),
      header: t("product.delete.title"),
      group: "dialog",
      accept: () =>
        void mutate(
          { id },
          {
            update(cache, { data }) {
              if (data?.deleteProduct) {
                cache.modify({
                  fields: {
                    products(existingRef: any[], { readField }) {
                      return existingRef.filter(
                        (eRef) => readField("id", eRef) !== id
                      );
                    },
                  },
                });
              }
            },
          }
        ),
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-warning",
    };
    confirm.require(data);
  }
  return { removeLoading, submitDelete };
};

export const usePriceHelper = () => {
  function _inclTax(vat: number, exclTax: number) {
    return exclTax + (exclTax * vat) / 100;
  }
  function _exclTax(vat: number, inclTax: number) {
    return (inclTax * 100) / (100 + vat);
  }
  function _vat(exclTax: number, inclTax: number) {
    return (100 * (inclTax - exclTax)) / exclTax;
  }
  return {
    _inclTax,
    _exclTax,
    _vat,
  };
};

import {
  EAccessMenu,
  MutationUpdateProductArgs,
  Product,
  UpdateProductInput,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { PRODUCT_FIELDS } from "@/graphql/product/product";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { computed, ref } from "vue";
import { STOCK_FIELDS } from "@/graphql/stock/stock";
import { trim } from "@/graphql/utils/utils";
import { subscriptionExpired } from "@/plugins/i18n";
import { getAccess, canNavigateAnywhere } from "@/utils/activity";
import { useValidation } from "vue3-form-validation";

type UpdateProductData = {
  updateProduct: Product;
};
const UPDATE_PRODUCT = gql`
    mutation UpdateProduct($image: Upload, $input: UpdateProductInput!) {
        updateProduct(image: $image, input: $input) {
            ${PRODUCT_FIELDS}
            stocks{${STOCK_FIELDS}}
        }
    }
`;

export const useUpdateProduct = (callback: () => void) => {
  const toast = useToast();
  const { t } = useI18n();
  const image = ref<File>(null);
  const cropperRef = ref<any>(null);
  const { form, validateFields, hasError } = useValidation({
    id: 0,
    categoryId: 1,
    reference: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    threshold: {
      $value: null,
    },
    type: {
      $value: 0,
    },
    changeable: {
      $value: 0,
    },
    name: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    notStorableInput: {
      vat: {
        $value: 0,
      },
      exclTax: {
        $value: 0,
      },
      inclTax: {
        $value: 0,
      },
      purchasePrice: {
        $value: 0,
      },
    },
  });
  const { loading, mutate, onDone } = useMutation<
    UpdateProductData,
    MutationUpdateProductArgs
  >(UPDATE_PRODUCT, { context: { hasUpload: true } });

  const canUpdate = computed(() => {
    const acc = getAccess(EAccessMenu.Product);
    const noRestriction = canNavigateAnywhere();
    const expired = Boolean(subscriptionExpired());
    return (
      (acc?.update || noRestriction) &&
      !expired &&
      !loading.value &&
      !hasError.value
    );
  });

  onDone(({ data, errors }) => {
    if (data?.updateProduct) {
      toast.add({
        severity: "success",
        summary: t("update.title"),
        detail: t("update.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      image.value = null;
      callback();
    } else {
      toast.add({
        severity: "warn",
        summary: t("update.title"),
        detail: t(errors[0]?.message ? "product.refExist" : "update.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  function submitUpdate(input: UpdateProductInput) {
    trim(input);
    void mutate({ image: image.value, input });
  }

  function validateUpdate() {
    validateFields().then(async (input) => {
      await cropperRef.value.getResult();
      submitUpdate(input);
    });
  }

  return { loading, image, form, validateUpdate, cropperRef, canUpdate };
};

import { MutationCreateProductArgs, Product } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { PRODUCT_FIELDS } from "@/graphql/product/product";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useValidation } from "vue3-form-validation";
import { activeActivity } from "@/plugins/i18n";
import { ref } from "vue";
import { formatDate, trim } from "@/graphql/utils/utils";
import { STOCK_FIELDS } from "@/graphql/stock/stock";

type CreateProductData = {
  createProduct: Product;
};

const CREATE_PRODUCT = gql`
    mutation CreateProduct($image: Upload, $input: CreateProductInput!){
        createProduct(image: $image, input: $input) {
            ${PRODUCT_FIELDS}
            stocks{${STOCK_FIELDS}}
        }
    }
`;
export const useCreateProduct = () => {
  const toast = useToast();
  const { t } = useI18n();
  const savedType = sessionStorage.getItem("product_type_state");
  const cropperRef = ref<any>(null);
  const { form, validateFields, hasError, resetFields } = useValidation({
    categoryId: {
      $value: 1,
    },
    type: {
      $value: savedType ? Number(savedType) : 0,
    },
    reference: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    name: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    threshold: {
      $value: null,
    },
    notStorableInput: {
      vat: {
        $value: activeActivity.value.taxRate,
      },
      exclTax: {
        $value: 0,
      },
      inclTax: {
        $value: 0,
      },
      purchasePrice: {
        $value: 0,
      },
    },
    stockInput: {
      quantity: {
        $value: 0,
      },
      alertAt: {
        $value: null,
      },
      expiredAt: {
        $value: null,
      },
    },
  });
  const image = ref<File>(null);
  const { loading, mutate, onDone } = useMutation<
    CreateProductData,
    MutationCreateProductArgs
  >(CREATE_PRODUCT, {
    context: { hasUpload: true },
    update(cache, { data }) {
      if (data?.createProduct) {
        cache.modify({
          fields: {
            products(existingRef: any[], { toReference }) {
              return [toReference(data.createProduct), ...existingRef];
            },
          },
        });
      }
    },
  });
  onDone(({ data, errors }) => {
    if (data?.createProduct) {
      toast.add({
        severity: "success",
        summary: t("product.create.title"),
        detail: t("product.create.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      image.value = null;
      // save the type state
      sessionStorage.setItem("product_type_state", String(form.type.$value));
      resetFields();
      form.type.$value = Number(sessionStorage.getItem("product_type_state"));
    } else {
      const exist = (errors[0]?.message || "").includes("refExist");
      toast.add({
        severity: "warn",
        summary: t("create.title"),
        detail: t(exist ? "product.refExist" : "create.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });
  function submitCreation() {
    validateFields()
      .then(async (input) => {
        await cropperRef.value.getResult();
        trim(input);
        const { expiredAt, alertAt } = input.stockInput;
        input.stockInput.expiredAt = expiredAt ? formatDate(expiredAt) : null;
        input.stockInput.alertAt = alertAt ? formatDate(alertAt) : null;
        if (input.type === 1) input.threshold = null;
        void mutate({ image: image.value, input });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  return {
    form,
    hasError,
    submitCreation,
    loading,
    image,
    resetFields,
    cropperRef,
  };
};

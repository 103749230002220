
import { defineComponent, ref } from "vue";
import { useFindAllCategories } from "@/graphql/category/find.all.categories";
import CreateProduct from "@/components/product/CreateProduct.vue";
import ProductCSV from "@/components/product/ProductCSV.vue";
import BarcodePrinter from "@/components/product/BarcodePrinter.vue";
import ProductList from "@/components/product/ProductList.vue";

export default defineComponent({
  name: "Product",
  components: { ProductList, BarcodePrinter, CreateProduct, ProductCSV },
  setup() {
    const ACTIVE_INDEX = "PRODUCT_LIST_ACTIVE_INDEX";
    const savedIndex = sessionStorage.getItem(ACTIVE_INDEX);
    const active = ref(parseInt(savedIndex || "0"));
    function saveActive(index: number) {
      sessionStorage.setItem(ACTIVE_INDEX, String(index));
    }
    return {
      ...useFindAllCategories(),
      active,
      saveActive,
    };
  },
});

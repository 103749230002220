import {
  CreateProductInput,
  MutationCreateMultiProductsArgs,
  NotStorableInput,
  Product,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { PRODUCT_FIELDS } from "@/graphql/product/product";
import { useMutation } from "@vue/apollo-composable";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/graphql/utils/utils";
import { STOCK_FIELDS } from "@/graphql/stock/stock";
import { computed, ref } from "vue";

type CreateMultiProductsData = {
  createMultiProducts: Product[];
};

export type RowMultipleProduct = NotStorableInput & {
  categoryId: number;
  reference: string;
  name: string;
  purchasePrice: number;
  exclTax: number;
  inclTax: number;
  vat: number;
  quantity: number;
  alertAt: Date;
  expiredAt: Date;
  threshold: number;
  type: number;
};

export type FieldError = {
  row: number;
  reason: string;
};

const CREATE_PRODUCT = gql`
    mutation CreateMultiProducts($input: [CreateProductInput!]!){
        createMultiProducts(input: $input) {
            ${PRODUCT_FIELDS}
            stocks{${STOCK_FIELDS}}
        }
    }
`;

const defaultRow = {
  categoryId: 1,
  reference: "",
  name: "",
  purchasePrice: 0,
  exclTax: 0,
  inclTax: 0,
  vat: 0,
  quantity: 0,
  expiredAt: null,
  alertAt: null,
  threshold: null,
  type: 0,
};

export const createMultiProducts = () => {
  const toast = useToast();
  const { t } = useI18n();
  const result = ref<string[]>([]);
  const hasSelectedFile = ref(false);
  const errors = ref<Set<string>>(new Set<string>());
  const importationErrors = ref<FieldError[]>([]);
  const rows = ref<RowMultipleProduct[]>([]);
  const { loading, mutate, onDone } = useMutation<
    CreateMultiProductsData,
    MutationCreateMultiProductsArgs
  >(CREATE_PRODUCT, {
    context: { hasUpload: true },
    update(cache, { data }) {
      if (data?.createMultiProducts) {
        cache.modify({
          fields: {
            products(existingRef: any[], { toReference }) {
              return data.createMultiProducts
                .map((prod) => toReference(prod))
                .concat(existingRef);
            },
          },
        });
      }
    },
  });
  const disableSubmit = computed(
    () => rows.value.length === 0 || loading.value
  );

  function addRow() {
    rows.value.unshift({ ...defaultRow });
  }

  function removeRow(index: number) {
    rows.value.splice(index, 1);
  }

  function addImportationError(row: number, ref: string, name: string) {
    const error = {
      row,
      reason: "",
    };
    if (!ref) {
      error.reason = t("product.refMissing");
    } else if (!name) error.reason = t("product.nameMissing");
    else error.reason = t("product.categoryNotFound");

    importationErrors.value.push(error);
  }

  onDone(({ data }) => {
    if (data?.createMultiProducts) {
      toast.add({
        severity: "success",
        summary: t("product.create.title"),
        detail:
          t("product.create.totalCreated") +
          " : " +
          data.createMultiProducts.length,
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      rows.value.length = 0;
      errors.value.clear();
      result.value.length = 0;
      hasSelectedFile.value = false;
    } else
      toast.add({
        severity: "warn",
        summary: t("create.title"),
        detail: t("create.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
  });

  function submitCreation() {
    const input: CreateProductInput[] = [];
    rows.value.forEach((row) => {
      if (row.reference && row.name) {
        input.push({
          categoryId: row.categoryId,
          name: row.name,
          notStorableInput: {
            vat: row.vat,
            exclTax: row.exclTax,
            inclTax: row.inclTax,
            purchasePrice: row.purchasePrice,
          },
          reference: row.reference,
          stockInput: {
            quantity: row.quantity,
            alertAt: row.alertAt ? formatDate(row.alertAt) : null,
            expiredAt: row.expiredAt ? formatDate(row.expiredAt) : null,
          },
          threshold: row.type ? null : row.threshold,
          type: row.type,
        });
      }
    });
    if (input.length > 0) void mutate({ input });
    else
      toast.add({
        severity: "warn",
        summary: t("create.title"),
        detail: t("product.create.noValidatedField"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
  }
  return {
    submitCreation,
    rows,
    removeRow,
    addRow,
    result,
    hasSelectedFile,
    errors,
    importationErrors,
    addImportationError,
    disableSubmit,
    loading,
  };
};

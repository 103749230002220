
import { defineComponent, onBeforeUnmount, reactive, ref } from "vue";
import JsBarcode from "jsbarcode";
import { printBarcodes } from "@/components/product/barcode-printer";

type Code = {
  value: string;
  image: string;
};
export default defineComponent({
  name: "BarcodePrinter",
  setup() {
    const codes = ref<Code[]>([]);
    const params = reactive({
      label: "",
      quantity: 1,
      height: 120,
      width: 180,
      gap: 10,
      columns: 4,
    });
    let canvas = document.createElement("canvas");

    /*const codesTable = computed(() => {
      const rows: Array<Code[]> = [];
      const row: Code[] = [];
      const length = codes.value.length;
      for (let cell = 0; cell < length; cell++) {
        row.push(codes.value[cell]);
        if (row.length == params.columns || length == cell + 1) {
          rows.push([...row]);
          row.length = 0;
        }
      }
      return rows;
    });*/

    function getCode(label: string) {
      label = label.replaceAll(" ", "");
      if (!label) return "";
      JsBarcode(canvas, label, {
        fontSize: 20,
        // marginTop: barcodeMargin,
      });
      return canvas.toDataURL("image/png");
    }

    function validate() {
      //const barcodeMargin = 20;
      for (let i = 0; i < params.quantity; i++) {
        const image = getCode(params.label);
        if (image) {
          codes.value.push({
            value: params.label,
            image,
          });
        }
        /*const ctx = canvas.getContext("2d");
        const x = -canvas.width * 0.57 + barcodeMargin;
        const y = barcodeMargin - barcodeMargin / 3;

        ctx.font = "normal 18px Monospace";
        ctx.textAlign = "center";
        ctx.fillText(String(params.quantity), x, y, canvas.width);*/
      }
    }

    function random() {
      const min = 1000000000;
      const max = 9999999999;
      return "000" + (Math.floor(Math.random() * (max - min + 1)) + min);
    }

    function autoGenerateCode() {
      params.label = random();
    }

    function removeCode(index: number) {
      codes.value.splice(index, 1);
    }

    const edit = reactive({
      index: 0,
      label: "",
    });
    const opEdit = ref<any>(null);
    function openEdition(event: any, index: number) {
      edit.label = codes.value[index].value;
      edit.index = index;
      opEdit.value?.toggle(event);
    }
    function autoGenerateCodeEdit() {
      edit.label = random();
    }

    function saveEdition() {
      const image = getCode(edit.label);
      if (image) {
        codes.value[edit.index] = {
          value: edit.label,
          image,
        };
        opEdit.value.hide();
      }
    }
    onBeforeUnmount(() => {
      canvas = null;
    });
    return {
      codes,
      params,
      validate,
      printBarcodes,
      autoGenerateCode,
      removeCode,
      opEdit,
      openEdition,
      edit,
      saveEdition,
      autoGenerateCodeEdit,
    };
  },
});


import { defineComponent } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { extractNumber, numberSeparators } from "@/graphql/utils/utils";
import { usePriceHelper } from "@/components/product/price-helper";

export default defineComponent({
  name: "PriceInput",
  props: ["vat", "exclTax", "inclTax", "error"],
  emits: ["update:vat", "update:exclTax", "update:inclTax"],
  setup(props, { emit }) {
    const { _inclTax, _exclTax } = usePriceHelper();
    function onExTaxChange(value: any, key = "vat") {
      if (!value) value = 0;
      switch (key) {
        case "vat":
          emit(`update:vat`, value);
          emit(`update:inclTax`, _inclTax(value, props.exclTax));
          break;
        case "price":
          emit(`update:exclTax`, value);
          emit(`update:inclTax`, _inclTax(props.vat, value));
          break;
      }
    }
    function onInTaxChange(value: any) {
      emit(`update:inclTax`, value);
      emit("update:exclTax", _exclTax(props.vat, value));
    }
    onExTaxChange(props.vat);

    function onExTaxChangeKeyup(value: string, key = "vat") {
      let val = extractNumber(value);
      if (key === "vat" && props.vat !== val) {
        val = val <= 100 ? val : props.vat;
        onExTaxChange(val, key);
      } else if (key === "price" && props.exclTax !== val)
        onExTaxChange(val, key);
    }
    function onInTaxChangeKeyup(value: string) {
      const val = extractNumber(value);
      if (props.inclTax !== val) onInTaxChange(val);
    }
    return {
      numberSeparators,
      activeActivity,
      onExTaxChange,
      onInTaxChange,
      onExTaxChangeKeyup,
      onInTaxChangeKeyup,
    };
  },
});


import { defineComponent, PropType, ref } from "vue";
import { FieldError } from "@/graphql/product/create.multi-products";

export default defineComponent({
  name: "ImportationError",
  props: {
    label: String,
    errors: Array as PropType<FieldError[]>,
  },
  setup(props) {
    const importation_op = ref<any>(null);
    return {
      importation_op,
      showErrors: (event: any) => {
        if ((props.errors as FieldError[]).length)
          importation_op.value.toggle(event);
      },
    };
  },
});

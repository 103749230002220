import { useTerminalMargins } from "@/components/terminal/terminal-margins";
import { printFrame } from "@/graphql/ticket/ticket.printer";

export const printBarcodes = () => {
  const { getMargins } = useTerminalMargins();
  const body = `<!DOCTYPE html>
      <head>
        <style>
            @page {
              margin: ${getMargins()};
              padding: 0;
           }
           @media print {
              body {
                  margin: 0;
                  width: 100%;
                  display: flex;
                  justify-content: center;
              }
              .code-controls {
                display: none;
              }
           }
        </style>
      </head>
      <html lang="en">
        <body>
          ${document.getElementById("barcodes")?.innerHTML}
        </body>
      </html>`;

  printFrame(body);

  return false;
};

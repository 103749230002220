import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-field" }
const _hoisted_2 = { for: "price" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = { for: "vat" }
const _hoisted_5 = { class: "p-field" }
const _hoisted_6 = { for: "inTax" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("product.exTax0")), 1),
      _createVNode(_component_InputNumber, {
        "model-value": _ctx.exclTax,
        locale: _ctx.numberSeparators[_ctx.activeActivity.separator],
        "max-fraction-digits": 20,
        min: 0,
        id: "price",
        mode: "decimal",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onExTaxChange($event.value, 'price'))),
        onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onExTaxChangeKeyup($event.target.value, 'price'))),
        class: _normalizeClass({ 'p-invalid': _ctx.error })
      }, null, 8, ["model-value", "locale", "class"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("product.vat")) + "(%)", 1),
      _createVNode(_component_InputNumber, {
        "model-value": _ctx.vat,
        id: "vat",
        mode: "decimal",
        locale: _ctx.numberSeparators[_ctx.activeActivity.separator],
        "max-fraction-digits": 20,
        min: 0,
        max: 100,
        onInput: _cache[2] || (_cache[2] = ($event: any) => (
        _ctx.onExTaxChange(Number($event.value || '0') <= 100 ? $event.value : _ctx.vat)
      )),
        onKeyup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onExTaxChangeKeyup($event.target.value)))
      }, null, 8, ["model-value", "locale"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("product.inTax0")), 1),
      _createVNode(_component_InputNumber, {
        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onInTaxChange($event.value))),
        onKeyup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onInTaxChangeKeyup($event.target.value))),
        "model-value": _ctx.inclTax,
        id: "inTax",
        mode: "decimal",
        locale: _ctx.numberSeparators[_ctx.activeActivity.separator],
        "max-fraction-digits": 20,
        min: 0,
        class: _normalizeClass({ 'p-invalid': _ctx.error })
      }, null, 8, ["model-value", "locale", "class"])
    ])
  ], 64))
}